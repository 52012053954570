/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { RichText } from '../models/rich-text'

import type { Equal, ExpectTrue } from './validation'

/**
 * We create a Zod schema that matches any object.
 * This is because we don't want to perform complex validation on the rich text objects
 */
export const LooseRichTextSchema = z.object({}).passthrough()

/**
 * For simplicity, we retrieve the type from the schema. It's roughly the object type
 */
export type LooseRichText = z.infer<typeof LooseRichTextSchema>

/**
 * This type will transform any field that is typed as RichText into the loose LooseRichText type
 */
export type LoosenRichTextFields<T> =
  T extends Array<infer U>
    ? Array<LoosenRichTextFields<U>>
    : T extends object
      ? {
          [K in keyof T]: RichText extends T[K]
            ? LooseRichText
            : LoosenRichTextFields<T[K]>
        }
      : RichText extends T
        ? LooseRichText
        : T

/**
 * TYPE TESTS
 */
type SimpleTest = ExpectTrue<
  Equal<
    { title: string; description: LooseRichText },
    LoosenRichTextFields<{ title: string; description: RichText }>
  >
>

type NestedFieldTest = ExpectTrue<
  Equal<
    { title: string; question: { text: LooseRichText } },
    LoosenRichTextFields<{ title: string; question: { text: RichText } }>
  >
>

type ArrayTest = ExpectTrue<
  Equal<
    {
      title: string
      questions: Array<{ title: LooseRichText; theme: string }>
    },
    LoosenRichTextFields<{
      title: string
      questions: Array<{ title: RichText; theme: string }>
    }>
  >
>

/**
 * To avoid doing a nested validation on the rich text objects, we reuse the LooseRichTextSchema. We'll just make sure it's an object
 */
const MediaSchema = z.object({
  image: z.string(),
  title: z.string(),
  description: LooseRichTextSchema,
})

type MediaProps = {
  image: string
  title: string
  description: RichText
}

/**
 * In the test to compare the Zod schema and the MediaProps, we transform the MediaProps "description" into a LooseRichText. now we can check if the types are the same
 */
type TestMedia = ExpectTrue<
  Equal<LoosenRichTextFields<MediaProps>, z.infer<typeof MediaSchema>>
>
