/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import {
  LooseRichTextSchema,
  type LoosenRichTextFields,
} from '../types/loose-rich-text'
import type { Equal, ExpectTrue } from '../types/validation'

import { type CTALink, CTALinkSchema } from './cta-link'
import { type Image, ImageSchema } from './image'
import { type RichText } from './rich-text'
/* TypeScript */
export const STRUCTURED_DATA_TYPE = {
  JSON_ONLY: 'JSON_ONLY',
  HTML_ONLY: 'HTML_ONLY',
  JSON_AND_HTML: 'JSON_AND_HTML',
} as const

export type StructuredDataType =
  (typeof STRUCTURED_DATA_TYPE)[keyof typeof STRUCTURED_DATA_TYPE]

type FaqQuestionImage = Omit<Image, 'widthMobile' | 'heightMobile'>

export type FaqQuestion = {
  id: string
  title: string
  text: RichText
  image?: FaqQuestionImage
  state?: 'expanded' | 'collapsed'
}

export type FaqApiProps = {
  technicalName?: string
  title: string
  subtitle?: string
  questions: FaqQuestion[]
  rendering?: StructuredDataType
  ctaLink?: CTALink
  whiteBackground?: boolean
}

/* Schemas */
export const StructuredDataTypeSchema = z.enum([
  'JSON_ONLY',
  'HTML_ONLY',
  'JSON_AND_HTML',
])

const FaqQuestionImageSchema = ImageSchema.omit({
  widthMobile: true,
  heightMobile: true,
})

export const FaqQuestionSchema = z.object({
  id: z.string(),
  title: z.string(),
  text: LooseRichTextSchema,
  image: FaqQuestionImageSchema.optional(),
  state: z.enum(['expanded', 'collapsed']).optional(),
})

export const FaqApiPropsSchema = z.object({
  technicalName: z.string().optional(),
  title: z.string(),
  subtitle: z.string().optional(),
  questions: z.array(FaqQuestionSchema),
  rendering: StructuredDataTypeSchema.optional(),
  ctaLink: CTALinkSchema.optional(),
  whiteBackground: z.boolean().optional(),
})

/* Schemas validation */
type TestFaqQuestion = ExpectTrue<
  Equal<LoosenRichTextFields<FaqQuestion>, z.infer<typeof FaqQuestionSchema>>
>
type TestFaqApiProps = ExpectTrue<
  Equal<LoosenRichTextFields<FaqApiProps>, z.infer<typeof FaqApiPropsSchema>>
>
